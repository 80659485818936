<template>
  <div>
    <ComHeader menuKey="product"></ComHeader>
    <!-- banner -->
    <div class="common-banner">
      <div class="container ui acenter">
        <img
          class="banner-icon animate__animated animate__backInLeft"
          src="@/assets/images/product/product-banner-icon.png"
          alt=""
        />
        <div class="f1 animate__animated animate__backInDown">
          <p class="banner-title">{{ apiShow.name }}</p>
          <div class="icon-auth">
            <img src="@/assets/images/product/banner-icon-auth.png" alt="" />
            <span class="banner-content">限企业实名用户使用</span>
          </div>
          <div class="banner-content">{{ apiShow.bannerTip }}</div>
          <a-button class="banner-btn" size="large" @click="toApply"
            >申请接入</a-button
          >
          <div class="banner-phone">商务联系 19182082426</div>
        </div>
        <div class="info">
          <div>商务咨询</div>
          <img
            class="headpic"
            src="@/assets/images/product/seives.png"
            alt=""
          />
          <div class="text-center">小李</div>
          <div class="phone">19182082426</div>
        </div>
      </div>
    </div>
    <!-- main -->
    <div class="container product-detail">
      <div class="product-item">
        <div class="item-title-center">产品描述</div>
        <p class="text-center product-item-content">{{ apiShow.describe }}</p>
      </div>
      <div class="product-item">
        <div class="item-title-center">应用场景</div>
        <p class="text-center product-item-content">{{ apiShow.scnen }}</p>
        <div
          class="scene-wrap"
          :class="id == 'cardValidity' ? 'scene-wrap-large' : ''"
          v-if="id == 'cardValidity'"
        >
          <div class="scene-item">
            <img src="@/assets/images/product/scene-db.png" alt="" />
            <span>代办业务核查</span>
          </div>
          <div class="scene-item">
            <img src="@/assets/images/product/scene-ys.png" alt="" />
            <span>防止使用他人遗失身份证</span>
          </div>
          <div class="scene-item">
            <img src="@/assets/images/product/scene-dq.png" alt="" />
            <span>盗取他人身份证</span>
          </div>
        </div>
        <div class="scene-wrap" v-else>
          <div class="scene-item">
            <img src="@/assets/images/product/scene-bank.png" alt="" />
            <span>银行</span>
          </div>
          <div class="scene-item">
            <img src="@/assets/images/product/scene-safe.png" alt="" />
            <span>保险</span>
          </div>
          <div class="scene-item">
            <img src="@/assets/images/product/scene-logistics.png" alt="" />
            <span>物流</span>
          </div>
          <div class="scene-item">
            <img src="@/assets/images/product/scene-shop.png" alt="" />
            <span>电商</span>
          </div>
          <div class="scene-item" v-if="id == 'cardOperator'">
            <img src="@/assets/images/product/scene-online.png" alt="" />
            <span>互联网金融</span>
          </div>
          <div class="scene-item" v-else>
            <img src="@/assets/images/product/scene-game.png" alt="" />
            <span>游戏直播</span>
          </div>
        </div>
      </div>
      <div class="product-item">
        <div class="item-title-center">量级及覆盖范围</div>
        <p class="text-center product-item-content">{{ apiShow.range }}</p>
        <div class="range-wrap" v-if="apiShow.id == 'cardOperator'">
          <div class="range-item">
            <img src="@/assets/images/product/range-yd.png" alt="" />
            <span>移动</span>
          </div>
          <div class="range-item">
            <img src="@/assets/images/product/range-lt.png" alt="" />
            <span>联通</span>
          </div>
          <div class="range-item">
            <img src="@/assets/images/product/range-dx.png" alt="" />
            <span>电信</span>
          </div>
        </div>
      </div>
      <div class="product-item">
        <div class="item-title-center">计费方式</div>
        <p class="text-center product-item-content">验证收费，具体见接口文档</p>
      </div>
    </div>
    <ComFooter></ComFooter>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useRoute, onBeforeRouteUpdate } from "vue-router";
import { message } from "ant-design-vue";
import ComHeader from "@/components/header.vue";
import ComFooter from "@/components/footer.vue";

export default defineComponent({
  components: { ComHeader, ComFooter },

  setup() {
    const route = useRoute();
    let id = route.query.id;

    let apiList = [
      {
        id: "cardValidity",
        name: "身份证有效期查询",
        bannerTip: "基于权威数据，提供用户身份证信息的有效性核验。",
        describe:
          "输入身份证相关信息，检测该身份证的有效性，返回身份证的有效性信息，是否挂失、注销等失效信息",
        scnen:
          "主要适用于代办业务的核查工作，防止代办人使用他人遗失的身份证或盗取他人的身份证来办理业务",
        range: "覆盖全国50亿的制证数据",
      },
      {
        id: "cardTwoItems",
        name: "实名信息比对技术服务【两项】",
        bannerTip:
          "基于权威数据，提供身份两项（姓名、公民身份号码）信息比对服务，实时快速核实身份信息是否真实有效。",
        describe:
          "输入姓名、身份证号，校验此两项真实性、是否匹配。返回相应核验结果。",
        scnen:
          "主要使用场景为银行、保险、物流、电商、游戏直播，需进行用户身份认证的平台，作为用户身份实名登记认证的依据",
        range: "覆盖全国50亿的制证数据",
      },
      {
        id: "cardOperator",
        name: "运营商三要素查询",
        bannerTip:
          "基于权威数据，提供姓名，身份证号，手机号查询，返回这三个信息是否一致的结果",
        describe:
          "输入姓名、身份证号、手机号，返回这三个信息是否一致的结果，结果显示一致或不一致。",
        scnen:
          "主要使用场景为银行、保险、物流、电商等平台，作为手机号码是否实名登记的依据",
        range: "三大运营商（联通/移动/电信）手机号，实时更新",
      },
      {
        id: "cardTwoItemsImg",
        name: "实人信息比对技术服务【两项+人像】",
        bannerTip:
          "基于权威数据，提供身份两项+人像（姓名、公民身份号码、人像）信息比对服务，实时快速核实身份信息是否真实有效。",
        describe:
          "输入姓名、身份证号、人像照片，校验真实性、是否匹配，返回相应核验结果。建议图片大小不超30kb、不小于5kb。",
        scnen:
          "主要使用场景为银行、保险、物流、电商、游戏直播，所有需进行用户实人认证的平台，作为用户身份实名登记认证的依据",
        range: "覆盖全国50亿的制证数据",
      },
    ];

    let apiShow = ref({});
    apiShow.value = apiList.find((item) => item.id == id) || {};

    onBeforeRouteUpdate((to) => {
      id = to.query.id;
      apiShow.value = apiList.find((item) => item.id == id) || {};
      console.log(apiShow);
    });

    const toApply = () => {
      message.warning({
        content: "请联系商务咨询",
        style: { color: "#fff" },
      });
    };

    return {
      apiList,
      apiShow,
      id,
      toApply,
    };
  },
});
</script>

<style lang="less" scoped>
.common-banner {
  background: url("../../assets/images/common-banner-bg.png") no-repeat;
  background-size: 100% 100%;
  .banner-icon {
    width: 268px;
    height: 223px;
    margin-right: 40px;
  }
  .banner-title {
    margin-bottom: 11px;
  }
  .icon-auth {
    color: #f5cd42;
    margin-bottom: 22px;
    img {
      width: 19px;
      margin-right: 10px;
    }
  }
  .banner-btn {
    background-color: #0270ff;
    border: 0;
    color: #fff;
    width: 130px;
    margin-top: 30px;
    &:hover {
      border: 1px solid #fff;
    }
  }
  .banner-phone {
    display: none;
  }
  .info {
    width: 210px;
    height: 240px;
    border-radius: 11px;
    padding: 18px;
    background-color: #fff;
    color: #000;
    font-size: 20px;
    text-align: center;
    margin-left: 90px;
    .headpic {
      width: 90px;
      height: 110px;
      display: block;
      margin: 5px auto;
    }
    .phone {
      font-size: 17px;
    }
  }
}
.product-detail {
  padding: 50px 0;
  color: #142d70;
  .title {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 80px;
  }
  .product-item {
    margin-bottom: 80px;
    font-size: 20px;
    .item-title {
      font-weight: 600;
      border-bottom: 1px solid #d8d8d8;
      padding-bottom: 10px;
      margin-bottom: 30px;
      font-size: 23px;
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 500px;
        height: 1px;
        background-color: #142d70;
        bottom: -1px;
      }
    }
    .item-title-center {
      font-weight: 600;
      border-bottom: 1px solid #d8d8d8;
      padding-bottom: 10px;
      font-size: 23px;
      text-align: center;
      width: 1000px;
      margin: 0 auto 30px;
      &::after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 160px;
        height: 4px;
        background-color: #142d70;
        position: relative;
        top: 10px;
      }
    }
    .scene-wrap {
      display: flex;
      justify-content: space-between;
      margin-top: 70px;
      &.scene-wrap-large {
        .scene-item {
          width: 25%;
        }
      }
      .scene-item {
        display: block;
        width: 200px;
        border-radius: 7px;
        border: 1px solid rgba(180, 180, 180, 0.45);
        text-align: center;
        padding-bottom: 10px;
        font-weight: 600;
        color: #000;
        img {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    .text-center {
      text-align: center;
    }
    .range-wrap {
      display: flex;
      justify-content: space-between;
      width: 1000px;
      margin: 0 auto;

      .range-item {
        display: block;
        width: 223px;
        border-radius: 7px;
        text-align: center;
        padding-bottom: 5px;
        font-weight: 600;
        color: #000;
        img {
          width: 100%;
          margin-top: 50px;
        }
      }
    }
  }
}
</style>
<style scoped lang="less">
.mobile {
  .common-banner {
    background: url("../../assets/images/common-banner-bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 80px 20px 0;
    display: flex;
    align-items: center;
    .info {
      display: none;
    }
    .banner-btn {
      display: none;
    }
    .banner-phone {
      display:block;
      width:150px;
      height:40px;
      line-height: 40px;
      text-align: center;
      background: rgba(17, 31, 113, 0.5);
      border-radius:4px;
      color: #64BAFF;
      font-size: 12px;
      font-weight: 600;
      margin-top: 10px;
    }
  }
  .product-detail {
    padding: 20px;
    .product-item {
      margin-bottom: 20px;
      .item-title-center {
        width: 96%;
      }
      .product-item-content {
        font-size: 14px;
      }
      .scene-wrap {
        margin-top: 20px;
        flex-wrap: wrap;
        .scene-item {
          width: 46%;
          margin: 0 5px 20px 5px;
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>